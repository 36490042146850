<template>
  <div>
    <clone-car
      :is-clone-car-active.sync="isCloneCarActive"
      :clone-car-id="cloneCarId"
    />
    <add-new-car
      :is-add-new-car-active.sync="isAddNewCarActive"
      :temp-car-vin="tempCarVin"
    />
    <add-new-car-charging-data
      :is-add-new-car-charging-data-active.sync="isAddNewCarChargingDataActive"
    />
    <edit-car
      :is-edit-car-active.sync="isEditCarActive"
      :edit-car-id="editCarId"
    />
    <h1
      style="font-weight: bold;"
    >
      Willkommen&nbsp;
      <br
        v-if="isMobile"
      >
      {{ authUser.firstName }} {{ authUser.lastName }}
    </h1>
    <br>
    <br>
    <b-row>
      <b-col
        cols="12"
        md="12"
        xs="12"
      >
        <!--style="background-color:#f8f8f8"-->
        <b-card>
          <b-card-body>
            <b-row
              align-v="center"
            >
              <b-col
                cols="12"
                xl="1"
                lg="1"
                md="12"
                sm="12"
                xs="12"
                :class="[isMobile === true ? 'text-center' : 'text-left']"
              >
                <b-avatar
                  variant="light-secondary"
                  size="70px"
                  style="min-width:70px;"
                >
                  <feather-icon
                    icon="UserIcon"
                    size="40px;"
                  />
                </b-avatar>
                <br
                  v-if="isMobile"
                >
                <br
                  v-if="isMobile"
                >
              </b-col>
              <b-col
                cols="12"
                xl="6"
                lg="6"
                md="12"
                sm="12"
                xs="12"
                :class="[isMobile === true ? 'text-center' : 'text-left']"
              >
                <h2>Kundendaten</h2>
                Hier finden Sie Ihre persönlichen Daten.
              </b-col>
              <b-col
                cols="12"
                xl="2"
                lg="2"
                md="2"
                sm="4"
                class="text-center"
              >
                &nbsp;
              </b-col>
              <b-col
                cols="12"
                lx="3"
                lg="3"
                md="8"
                sm="2"
              >
                <!-- v-if="userStatus.status == 200" -->
                <!--  -->
                <!-- variant="outline-secondary" -->
                <br
                  v-if="isMobile"
                >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-secondary"
                  pill
                  block
                  :to="{ name: 'user-detail', params: { userId: authUser.id, forceRedirect: false } }"
                >
                  Detail
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col
        v-if="carList.length < 1"
        cols="12"
        md="12"
        :style="[isMobile === true ? 'padding:30px;' : 'margin-top:50px; margin-bottom:25px; margin-left:20px;']"
      >
        <b-row
          class="center"
          align-v="center"
          style="text-align:center;"
        >
          <b-col>
            <h1 style="text-align:center;">
              Keine Fahrzeuge vorhanden
            </h1>
          </b-col>
        </b-row>
        <b-row
          class="center"
          align-v="center"
        >
          <b-col>
            <h5 style="text-align:center;">
              Es wurden keine Fahrzeugpapiere hochgeladen.
            </h5>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        v-if="!isMobile && carList.length < 1"
        cols="12"
        xl="5"
        lg="5"
      >
        &nbsp;
      </b-col>
      <b-col
        v-if="currentBreakPointVal==='md' && carList.length < 1"
        cols="12"
        md="3"
      >
        &nbsp;
      </b-col>
      <b-col
        v-if="partnerData.userCanRegisterBonus !== true"
        cols="12"
        xl="12"
        lg="12"
        md="12"
        sm="12"
        xs="12"
        :style="[isMobile === true ? 'padding:30px;' : 'margin-bottom:25px; margin-left:20px;']"
      >
        Die Aktion für 2024 ist abgelaufen, Fahrzeugneuanmeldungen für 2024 sind daher leider nicht mehr möglich. Wenn 2025 eine neue Aktion startet, werden Sie über die üblichen Kanäle informiert.
      </b-col>

      <b-col
        v-if="carList.length < 1 && partnerData.userCanRegisterBonus === true"
        cols="12"
        :style="[isMobile === true ? 'padding-left:30px;padding-right:30px;' : '']"
      >
        <b-alert
          :variant="partnerData.colorScheme"
          show
        >
          <div class="alert-body" style="padding: 0.71rem 3rem;">
            <b-row
              class="align-items-center"
            >
              <b-col
                cols="12"
                md="9"
              >
                <p v-if="partnerData.bonusType === 1 || partnerData.bonusType === 2">
                  {{ partnerData.partnerBonusTitle }}
                  <br>
                  <small>inkl. USt, AGB für aliquote Auszahlung beachten</small>
                </p>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-button
                  class="mt-2 mt-md-0"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="partnerData.colorScheme"
                  pill
                  block
                  @click="isAddNewCarActive = true"
                >
                  Fahrzeug hinzufügen
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-alert>
      </b-col>
      <b-col
        v-if="carList.length > 0 && partnerData.userCanRegisterBonus === true"
        cols="12"
      >
        <b-alert
          :variant="partnerData.colorScheme"
          show
        >
          <div class="alert-body" style="padding: 0.71rem 3rem;">
            <b-row
              class="align-items-center"
            >
              <b-col
                cols="12"
                md="9"
              >
                <p v-if="partnerData.bonusType === 1 || partnerData.bonusType === 2">
                  {{ partnerData.partnerBonusTitle }}
                  <br>
                  <small>inkl. USt, AGB für aliquote Auszahlung beachten</small>
                </p>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-button
                  class="mt-2 mt-md-0"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="partnerData.colorScheme"
                  pill
                  block
                  @click="isAddNewCarActive = true"
                >
                  Fahrzeug hinzufügen
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-alert>
      </b-col>
      <b-col
        v-if="carList.length > 0"
        cols="12"
        md="12"
        style="margin-top:35px; margin-bottom:0px;"
      >
        <div
          class="divider"
          text-position="left"
        >
          meine Fahrzeuge
        </div>
      </b-col>

      <b-col
        v-for="item in carList"
        :key="item.car.id"
        cols="12"
        md="12"
      >
        <b-card>
          <b-card-body>
            <b-row
              align-v="center"
            >
              <b-col
                cols="12"
                xl="1"
                lg="1"
                md="12"
                sm="12"
                xs="12"
                class="text-center"
              >
                <h2 style="color:#82868b; margin:0px;">
                  {{ item.bonus.dateFrom | moment("YYYY") }}
                </h2>
              </b-col>
              <b-col
                cols="12"
                xl="9"
                lg="8"
                sm="12"
                xs="12"
                :class="[isMobile === true ? 'text-center' : 'text-left']"
              >
                <!--
                <b-badge
                  :variant="partnerData.colorScheme"
                >
                  {{ getStatusTitle(item.bonus.currentStatus) }}
                </b-badge>
                -->
                <b-badge
                  :style="getStatusColor(item.bonus.currentStatus)"
                >
                  {{ getStatusTitle(item.bonus.currentStatus) }}
                </b-badge>
              </b-col>
            </b-row>
            <b-row
              align-v="center"
              style="margin-top:20px;"
            >
              <b-col
                cols="12"
                xl="1"
                lg="1"
                md="12"
                sm="12"
                xs="12"
                :class="[isMobile === true ? 'text-center' : 'text-left']"
              >
                <b-avatar
                  :variant="`light-${partnerData.colorScheme}`"
                  size="70px"
                >
                  <feather-icon
                    icon="BatteryChargingIcon"
                    size="40px;"
                  />
                </b-avatar>
                <br
                  v-if="isMobile"
                >
                <br
                  v-if="isMobile"
                >
              </b-col>
              <b-col
                cols="12"
                xl="8"
                lg="8"
                :class="[isMobile === true ? 'text-center' : 'text-left']"
              >
                <h4>{{ item.car.title }} (<span v-if="item.bonus.useType==1">Privat</span><span v-if="item.bonus.useType==2">Firmenwagen</span>)</h4>
                <h2>{{ item.bonus.street }}, {{ item.bonus.zip }} {{ item.bonus.city }}</h2>
                <b-row>
                  <b-col
                    lg="12"
                  >
                    {{ item.car.vin }}
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                lx="3"
                lg="3"
              >
                <br
                  v-if="isMobile"
                >
                <br
                  v-if="isMobile"
                >
                <b-button
                  v-if="item.bonus.currentStatus <= 11 || item.bonus.currentStatus > 21"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="`outline-${partnerData.colorScheme}`"
                  pill
                  block
                  @click="openEditSidebar(item.car.id)"
                >
                  Detail
                </b-button>
                <b-button
                  v-if="item.canBeCloned && partnerData.userCanRegisterBonus === true && item.bonus.currentStatus >= 30 && (partnerData.activeYear != item.bonus.dateFrom.substring(0, 4))"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="`outline-${partnerData.colorScheme}`"
                  pill
                  block
                  @click="openCloneCarSidebar(item.car.id)"
                  style="font-size: 12px;"
                >
                  Fahrzeug für {{partnerData.activeYear}} beantragen
                </b-button>
                <b-button
                  v-if="item.bonus.currentStatus == 15"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="`${partnerData.colorScheme}`"
                  pill
                  block
                  @click="openEditSidebar(item.car.id)"
                >
                  Daten korrigieren
                </b-button>
                <span
                  v-if="item.bonus.currentStatus > 15 && item.bonus.currentStatus <= 21"
                >
                  Bitte klicken Sie hier, um die Daten aufzurufen, zu kontrollieren und zu bestätigen.
                  <br><br>
                </span>
                <b-button
                  v-if="item.bonus.currentStatus > 15 && item.bonus.currentStatus <= 21"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="`${partnerData.colorScheme}`"
                  pill
                  block
                  @click="openEditSidebar(item.car.id)"
                >
                  Bestätigen
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>

      <!--
        //to be used in 2024
      <b-col
        cols="12"
        md="12"
      >
        <app-collapse
          accordion
          type="margin"
        >
          <app-collapse-item
            title="Gesamtübersicht"
            :is-visible="false"
          >
            <b-table
              ref="carRecordsRef"
              :items="tableDataAll"
              :fields="tableColumnsAll"
              responsive
              primary-key="id"
              class="position-relative"
              show-empty
              empty-text="Keine passenden Einträge gefunden"
              striped="false"
              small="true"
            >
              <template #cell(status)="data">
                <b-badge
                  variant="success"
                  style="font-size:9px;"
                >
                  {{ data.item.status }}
                </b-badge>
              </template>
              <template #cell(actions)="data">
                <b-dropdown
                  variant="link"
                  size="sm"
                >

                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item>
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </app-collapse-item>
        </app-collapse>
      </b-col>
      -->
    </b-row>
    <b-modal
      id="new-car-success-modal"
      ref="newCarSuccessModal"
      centered
      title="Erfolg"
      ok-only
      ok-title="OK"
      :ok-variant="partnerData.colorScheme"
    >
      <b-card-text>
        Danke, dass Sie sich für unseren Bonus entschieden haben. Sie erhalten ein e-mail mit der Auftragsbestätigung. Prüfen Sie bitte Ihren Maileingang oder Spam-Ordner.
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  ref,
  onUnmounted,
  computed,
  watch,
} from '@vue/composition-api'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BBadge,
  BTable,
  BFormSelect,
  BModal,
  VBModal,
  BCardText, BAlert,
} from 'bootstrap-vue'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import Ripple from 'vue-ripple-directive'

import router from '@/router'

import store from '@/store'
import homeStoreModule from './homeStoreModule'

import CloneCar from './sidebar/CloneCar.vue'
import AddNewCar from './sidebar/AddNewCar.vue'
import AddNewCarChargingData from './sidebar/AddNewCarChargingData.vue'
import EditCar from './sidebar/EditCar.vue'

import { bonusStatus } from '../../enums'
import axios from '@axios'

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BBadge,
    AppCollapse,
    AppCollapseItem,
    BTable,
    AddNewCar,
    CloneCar,
    AddNewCarChargingData,
    EditCar,
    BFormSelect,
    BModal,
    BCardText,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  setup(props, ctx) {
    const carList = ref([])
    const APP_HOME_STORE_MODULE_NAME = 'app-home'

    // Register module
    if (!store.hasModule(APP_HOME_STORE_MODULE_NAME)) store.registerModule(APP_HOME_STORE_MODULE_NAME, homeStoreModule)

    // UnRegister on leave
    /*
    onUnmounted(() => {
      if (store.hasModule(APP_HOME_STORE_MODULE_NAME)) store.unregisterModule(APP_HOME_STORE_MODULE_NAME)
    })
    */

    const isMobile = ref(true)
    const currentBreakPointVal = ref(store.getters['app/currentBreakPoint'])
    if (store.getters['app/currentBreakPoint'] === 'xl' || store.getters['app/currentBreakPoint'] === 'lg') {
      isMobile.value = false
    }

    const currentBreakPoint = computed(() => store.getters['app/currentBreakPoint'])
    watch(currentBreakPoint, val => {
      currentBreakPointVal.value = val
      if (val === 'xl' || val === 'lg') {
        isMobile.value = false
      } else {
        isMobile.value = true
      }
    })

    const partner = ref(window.$cookies.get('partner'))
    const partnerData = ref(window.$cookies.get('partnerData'))
    const getHrefStyle = () => `color:var(--${partnerData.value.colorScheme})`
    document.title = partnerData.value.htmlPageTitle
    document.querySelector("link[rel*='icon']").href = `favicon_${partnerData.value.key}.ico`

    const appIdElem = document.getElementById('app')
    appIdElem.style.backgroundImage = 'none'

    const carAddedAt = computed(() => store.getters[`${APP_HOME_STORE_MODULE_NAME}/carAddedAt`])
    const carUpdateddAt = computed(() => store.getters[`${APP_HOME_STORE_MODULE_NAME}/carUpdateddAt`])
    const isAddNewCarActive = ref(false)
    const isCloneCarActive = ref(false)
    const isAddNewCarChargingDataActive = ref(false)
    const isEditCarActive = ref(false)
    const editCarId = ref('')
    const cloneCarId = ref('')
    const tempCarVin = ref('')

    const authUser = window.$cookies.get('userData')
    if (typeof (authUser.newCarVin) !== 'undefined') {
      tempCarVin.value = authUser.newCarVin
    }

    const fetchData = () => {
      store
        .dispatch(`${APP_HOME_STORE_MODULE_NAME}/list`, { userId: authUser.id, partner: window.$cookies.get('partner') })
        .then(response => {
          carList.value = response.data

          if (typeof (authUser.newCarVin) !== 'undefined') {
            let vinExists = false
            for (let i = 0; i < response.data.length; i += 1) {
              if (response.data[i].car.vin.toUpperCase() === authUser.newCarVin.toUpperCase()) {
                vinExists = true
              }
            }

            if (partnerData.value.standardLogin === true && carList.value.length > 0) {
              vinExists = true
            }

            if (vinExists === false) {
              tempCarVin.value = authUser.newCarVin
              isAddNewCarActive.value = false
            } else {
              tempCarVin.value = ''
              isAddNewCarActive.value = false
            }
          }
        })
        .catch(error => {
          //  console.log(error.response.status)
          if (error.response.status === 401) {
            window.$cookies.remove('userData')
            window.$cookies.remove('accessToken')
            window.$cookies.remove('refreshToken')
            router.go('/login')
          }
        })
    }

    const showModal = () => {
      ctx.refs.newCarSuccessModal.show()
    }

    const openEditSidebar = id => {
      editCarId.value = id
      isEditCarActive.value = true
    }

    const openCloneCarSidebar = id => {
      cloneCarId.value = id
      isCloneCarActive.value = true
    }

    const getStatusTitle = statusId => bonusStatus[parseInt(statusId, 10)]

    const getStatusColor = statusId => {
      let backgroundColor = 'rgba(40, 118, 251, 0.12)'
      let color = '#2876fb'

      if (parseInt(statusId, 10) === 10) {
        backgroundColor = 'rgba(40, 118, 251, 0.12)'
        color = '#2876fb'
      } else if (parseInt(statusId, 10) === 15) {
        backgroundColor = '#df4d12'
        color = '#ffffff'
      } else if (parseInt(statusId, 10) === 20) {
        backgroundColor = 'rgba(130, 134, 139, 0.12)'
        color = '#82868b'
      } else if (parseInt(statusId, 10) === 21) {
        backgroundColor = 'rgba(130, 134, 139, 0.12)'
        color = '#df4d12'
      } else if (parseInt(statusId, 10) === 25) {
        backgroundColor = 'rgba(130, 134, 139, 0.12)'
        color = '#df4d12'
      } else if (parseInt(statusId, 10) === 30) {
        backgroundColor = '#2876fb'
        color = '#ffffff'
      } else if (parseInt(statusId, 10) === 40) {
        backgroundColor = 'rgba(223, 77, 18, 0.12)'
        color = '#df4d12'
      } else if (parseInt(statusId, 10) === 50) {
        backgroundColor = '#df4d12'
        color = '#ffffff'
      } else if (parseInt(statusId, 10) === 60) {
        backgroundColor = '#7c9d2b'
        color = '#ffffff'
      } else if (parseInt(statusId, 10) === 70) {
        backgroundColor = '#df4d12'
        color = '#ffffff'
      } else if (parseInt(statusId, 10) === 80) {
        backgroundColor = '#000000'
        color = '#ffffff'
      }

      return `background-color:${backgroundColor}; color:${color};`
    }

    watch(carAddedAt, (val, oldVal) => {
      if (val > oldVal) {
        showModal()
        fetchData()
      }
    })

    watch(carUpdateddAt, (val, oldVal) => {
      if (val > oldVal) {
        delete authUser.newCarVin
        fetchData()
      }
    })

    store
      .dispatch(`${APP_HOME_STORE_MODULE_NAME}/canAdd`, { userId: authUser.id })
      .then(response => {
        if (response.data === true) {
          fetchData()
        } else {
          router.push({ name: 'user-detail', params: { userId: authUser.id, forceRedirect: true } })
        }
      })
      .catch(error => {
        console.log(error)
      })

    const tableColumns = [
      { key: 'powerUsage', label: 'Geladene Strommenge [kWh]*' },
      { key: 'dateFrom', label: 'Startzeitpunkt', thStyle: { width: '150px !important' } },
      { key: 'dateTo', label: 'Endzeitpunkt', thStyle: { width: '150px !important' } },
    ]

    const tableColumnsAll = [
      { key: 'year', label: 'Jahr', thStyle: { width: '120px !important' } },
      // . { key: 'powerUsage', label: 'Geladene Strommenge [kWh]*', thStyle: { width: '250px !important' } },
      { key: 'carTitle', label: 'Fahrzeug' },
      { key: 'vin', label: 'Fahrzeugidentifizierungsnummer' },
      { key: 'dateFrom', label: 'Startzeitpunkt', thStyle: { width: '150px !important' } },
      { key: 'dateTo', label: 'Endzeitpunkt', thStyle: { width: '150px !important' } },
      { key: 'status', label: 'Status', thStyle: { width: '80px !important' } },
    ]

    // /demo data
    const tableData = [
      {
        powerUsage: '105,28', carTitle: 'Volvo V70', vin: '4V4NC9JH21N322086', dateFrom: '01.01. 2021', dateTo: '31.12. 2021',
      },
      {
        powerUsage: '187,83', carTitle: 'Lexus GS', vin: 'JTHBH96S685054656', dateFrom: '01.08. 2020', dateTo: '31.12. 2020',
      },
    ]

    const tableDataAll = [
      {
        year: 2022, powerUsage: '2500', carTitle: 'VW ID4 (Privat)', vin: 'WWWD25LE1DU042025', dateFrom: '01.01. 2022', dateTo: '31.12. 2022', status: 'Bonus gewährt',
      },
      {
        year: 2022, powerUsage: '3520', carTitle: 'Tesla Model 3 (Privat)', vin: 'KMHD25LE1DU042025', dateFrom: '01.01. 2022', dateTo: '31.12. 2022', status: 'Bonus gewährt',
      },
      {
        year: 2021, powerUsage: '7600', carTitle: 'VW ID4 (Privat)', vin: 'WWWD25LE1DU042025', dateFrom: '01.05. 2021', dateTo: '31.12. 2021', status: 'Bonus gewährt',
      },
      {
        year: 2021, powerUsage: '4563', carTitle: 'Tesla Model 3 (Privat)', vin: 'KMHD25LE1DU042025', dateFrom: '01.01. 2021', dateTo: '31.12. 2021', status: 'Bonus gewährt',
      },
      {
        year: 2020, powerUsage: '1980', carTitle: 'Tesla Model 3 (Privat)', vin: 'WWWD25LE1DU042025', dateFrom: '01.06. 2021', dateTo: '31.12. 2021', status: 'Bonus gewährt',
      },
    ]

    const getPartnerData = partnerKey => {
      axios
        .get(`/partner/get/${partnerKey}`)
        .then(response => {
          if (response.status === 200) {
            window.$cookies.set('partnerData', response.data, Infinity, null, null, true, 'None')
            partnerData.value = response.data
          }
        })
    }

    getPartnerData(window.$cookies.get('partner'))

    return {
      carList,
      authUser,
      tableColumns,
      tableData,
      tableColumnsAll,
      tableDataAll,
      isAddNewCarActive,
      isCloneCarActive,
      isAddNewCarChargingDataActive,
      isEditCarActive,
      showModal,
      partner,
      partnerData,
      getHrefStyle,
      openEditSidebar,
      openCloneCarSidebar,
      editCarId,
      tempCarVin,
      cloneCarId,
      isMobile,
      currentBreakPointVal,
      getStatusTitle,
      getStatusColor,
    }
  },
}
</script>
<style>
.divider {
  display: flex;
  align-items: center;
  padding: 0 0rem;
  font-size: 20px;
  font-weight: bold;
}

.divider:before,
.divider:after {
  content: '';
  flex: 0 1 100%;
  border-bottom: 1px solid #ccc;
  margin: 0 1rem;
}

.divider:before {
  margin-left: 0;
}

.divider:after {
  margin-right: 0;
}

.divider[text-position="right"]:after,
.divider[text-position="left"]:before {
  content: none;
}
</style>
